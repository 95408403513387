import { FC } from 'react';

import { ChatProvider } from '@apps/chat/shared/multilabel/src/ChatProvider';
import type { AppProps } from '@dxp-next';

const GrootzakelijkApp: FC<AppProps> = ({ Component, pageProps: { session, ...pageProps }, router }) => {
  return (
    <ChatProvider>
      <Component {...pageProps} />
    </ChatProvider>
  );
};

export default GrootzakelijkApp;
