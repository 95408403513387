import { ChatArgs, LayoutMode, Namespace } from '@components/chat/useChat';
import { assign, createMachine, fromPromise } from '@eneco-packages/xstate-custom/xstate';

import * as Seamly from './Seamly';

/*
 * The chat provider uses a state machine to help manage state requirements:
 *
 * - Seamly Chat can be re-activated from `sessionStorage` (on page load).
 * - Seamly Chat can be rendered either "inline" in the DOM (<InlineChat />),
 *   or with a button ("minimized" state), activating a full-height "window" (<Chat />).
 * - Only a single Seamly Chat instance should be active at any time,
 *   while the "inline" chat instance must have precedence.
 *
 * - One major reason for this state machine is to make sure a mounted inlined
 *   chat may turn into a windowed chat (or vice versa) after navigation.
 * - Another reason is to prevent unnecessary destroy/init sequences after
 *   navigation and component re-renders.
 *
 * A nice benefit of the provider is to manage lazy-loading the external script
 * only once, after the "idle" state (i.e. not loaded at all if inactive).
 *
 * Components can request to `mount()` the chat anywhere (either `inline` or
 * `window`; `open` or `minimized`) with the `useChat` hook. That request is
 * `send()` to the machine, and its actions result in activities and
 * side-effects calling the Seamly API.
 */

type ChatContext = {
  namespace: Namespace;
  parentElement: HTMLDivElement | undefined;
  layoutMode: LayoutMode;
  hideOnNoUserResponse: boolean;
};

type Event = {
  namespace: Namespace;
  topic?: string;
  askText?: string;
  variables?: ChatArgs['variables'];
  context?: ChatArgs['context'];
  api?: ChatArgs['api'];
};
type Inline = { type: 'inline:open'; parentElement: HTMLDivElement };
type Minimized = { type: 'window:minimized' };
type Windowed = { type: 'window:open' | 'window:open:copilot' };

export type ChatEvent = (Inline & Event) | (Minimized & Event) | (Windowed & Event);
export default createMachine({
  id: 'chat',
  context: {
    namespace: 'consumer',
    parentElement: undefined,
    layoutMode: 'window',
    hideOnNoUserResponse: false,
  },
  types: {
    events: {} as ChatEvent,
    context: {} as ChatContext,
  },
  initial: 'idle',
  states: {
    idle: {
      on: {
        'inline:open': 'inline',
        'window:minimized': 'window',
        'window:open': 'window.open',
        // openCopilotMode is a special case when sending topic and message in 1 call
        'window:open:copilot': 'window.openCopilotMode',
      },
    },
    window: {
      entry: ['setNamespace', 'setWindow', 'setVariables'],
      invoke: {
        id: 'runSeamly',
        src: 'runSeamly',
        input: ({ context, event }: { context: ChatContext; event: ChatEvent }) => ({
          context,
          event,
        }),
      },
      initial: 'minimized',
      states: {
        minimized: {
          entry: ['minimize', 'sendTopic', 'sendMessage'],
          on: {
            'window:open:copilot': 'openCopilotMode',
            'window:open': 'open',
          },
        },
        open: {
          entry: ['open', 'sendTopic', 'sendMessage'],
          on: {
            'window:minimized': 'minimized',
            'window:open': {
              actions: ['open', 'sendTopic', 'sendMessage'],
            },
          },
        },
        // For belgium team the open event triggers
        // a special function which sends topic and message
        // in one go
        openCopilotMode: {
          entry: ['open', 'sendTopicAndMessage'],
          on: {
            'window:minimized': 'minimized',
            'window:open:copilot': {
              actions: ['open', 'sendTopicAndMessage'],
            },
          },
        },
      },
      on: {
        'inline:open': 'inline',
      },
    },
    inline: {
      entry: ['setNamespace', 'setInline', 'setParentElement', 'setVariables'],
      invoke: {
        src: 'runSeamly',
        input: ({ context, event }: { context: ChatContext; event: ChatEvent }) => ({
          context,
          event,
        }),
      },
      initial: 'open',
      states: {
        open: {
          entry: ['sendTopic', 'sendMessage'],
          on: {
            'inline:open': {
              actions: ['open', 'sendTopic', 'sendMessage'],
            },
          },
        },
      },
      on: {
        'window:open': 'window.open',
        'window:minimized': 'window.minimized',
      },
    },
  },
}).provide({
  actions: {
    setNamespace: assign(({ event }) => ({
      namespace: event.namespace,
    })),
    setWindow: assign(() => ({
      layoutMode: 'window',
    })),
    setInline: assign(() => ({
      layoutMode: 'inline',
    })),
    setParentElement: assign(({ event }) => ({
      parentElement: (event as Inline).parentElement,
    })),
    setVariables: assign(({ context, event }) => {
      const { namespace } = context;
      // @ts-ignore ChatEvent is union
      const { hideOnNoUserResponse, variables } = event;

      if (variables) {
        Seamly.setVariables({ variables, namespace });
      }
      return { hideOnNoUserResponse };
    }),
    minimize: ({ context }: { context: ChatContext }) => {
      Seamly.setVisibility({ namespace: context.namespace, visibility: 'minimized' });
    },
    open: ({ context }: { context: ChatContext }) => {
      Seamly.setVisibility({ namespace: context.namespace, visibility: 'open' });
    },
    sendMessage: ({ context, event }: { context: ChatContext; event: ChatEvent }) => {
      const { namespace, parentElement } = context;
      // @ts-ignore ChatEvent is union
      const { type, askText, topic } = event;

      const [layoutMode] = type.split(':') as [LayoutMode];
      if (askText && !topic) {
        Seamly.setVariables({
          variables: { event: 'start_with_question' },
          namespace: context.namespace,
        });
        Seamly.askText({ askText, namespace });
        if (parentElement && layoutMode === 'inline') {
          setTimeout(() => parentElement.scrollIntoView({ behavior: 'smooth' }), 1000);
        }
      }
    },
    sendTopic: ({ context, event }: { context: ChatContext; event: ChatEvent }) => {
      const { namespace, parentElement } = context;
      // @ts-ignore ChatEvent is union
      const { type, topic } = event;
      const [layoutMode] = type.split(':') as [LayoutMode];

      if (topic) {
        Seamly.topic({ topic, namespace });
        if (parentElement && layoutMode === 'inline') {
          setTimeout(() => parentElement.scrollIntoView({ behavior: 'smooth' }), 1000);
        }
      }
    },
    sendTopicAndMessage: ({ context, event }: { context: ChatContext; event: ChatEvent }) => {
      const { namespace } = context;
      const { topic, askText } = event;
      if (topic) {
        Seamly.topic({ topic, namespace, askText });
      }
    },
  },
  actors: {
    runSeamly: fromPromise(async ({ input }) => {
      const { namespace, parentElement, layoutMode, hideOnNoUserResponse } = input['context'];
      const { context: initContext, api } = input['event'];
      await Seamly.init({
        namespace,
        layoutMode,
        parentElement,
        hideOnNoUserResponse,
        context: initContext,
        api,
      });
      return async () => {
        await Seamly.destroy(namespace);
      };
    }),
  },
});
