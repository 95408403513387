import { createContext, useContext } from 'react';

export type Namespace = 'consumer' | 'zzp-mkb' | 'business' | 'consumer-be' | 'b2b-be';
export type LayoutMode = 'window' | 'inline' | null;
export type Visibility = 'open' | 'minimized' | 'hidden' | null;

export type ChatArgs = {
  namespace?: Namespace;
  layoutMode?: LayoutMode;
  visibility?: Visibility;
  parentElement?: HTMLDivElement;
  topic?: string;
  askText?: string;
  sendTopicAndAskText?: boolean;
  fallbackMessage?: string;
  hideOnNoUserResponse?: boolean;
  context?: {
    contentLocale?: string;
    userLocale?: string;
    variables?: {
      name?: string;
      event?: string;
    };
  };
  api?: {
    getConversationAuthToken: () => string | undefined;
  };
  variables?: {
    personalisation?: {
      context?: {
        isBusinessCustomer: boolean;
      };
      values?: {
        customerId: number;
      };
    };
    [key: string]: unknown;
  };
};

type MountFn = (params: ChatArgs) => void;
export type ChatContextValue = [MountFn];

export const ChatContext = createContext<ChatContextValue | null>(null);

export function useChat(): ChatContextValue {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat should only be using within a <ChatProvider>');
  }
  return context;
}
